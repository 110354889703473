import React, { useReducer } from 'react';
import './App.css';

import * as r from 'react-router-dom';

import * as p from './pages';

const initialTopic = () => ([
  {topic:"Topic 1",timer:3000},
  {topic:"Second Topic",timer:3000},
  {topic:"End Topic",timer:2000},
  {topic:"Finish",timer:0}
]);
const emptyTopic = () => ({topic:"",timer:"1000"});

const agendaReducer = (s,a)=>{
  // console.log(s,a)
  if(!a) return [...s,emptyTopic()];
  if(!a.target) {
    s.splice(a.id,1);
    return [...s];
  }
  s[a.id][a.target] = a.value;
  return [...s];

}

function App() {
  let [agenda,setAgenda] = useReducer(agendaReducer,initialTopic());

  return <r.BrowserRouter><div className="container">
    <r.Switch>
      <r.Route path="/run"><p.RunPage agenda={agenda} /></r.Route>
      <r.Route path="/"><p.OptionsPage agenda={agenda} setAgenda={setAgenda} /></r.Route>
    </r.Switch>
  </div></r.BrowserRouter>;
}

export default App;
