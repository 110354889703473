import React, {useState,useEffect} from 'react';
import * as r from 'react-router-dom';

const TimerInputSet = ({id,o,setAgenda}) => {
    let [h,setH] = useState(0);
    let [m,setM] = useState(0);
    let [s,setS] = useState(0);

    useEffect(()=>{
        let h = Math.round(o.timer/3600000);
        let hr = o.timer%3600000;
        let m = Math.round(hr / 60000);
        let mr = hr % 60000;
        let s = Math.round(mr / 1000);
        setH(h);
        setM(m);
        setS(s);
    },[o.timer]);

    const getTotal = (h,m,s) => {
        return (s*1000) + (m*1000*60) + (h*1000*60*60);
    }

    return <div className="display-flex">
        <div className="form-select" style={{margin:'0 0.2em'}}>
            <select value={h} onChange={(e)=>{
                // console.log("making hour")
                setS(+e.target.value);
                setAgenda({id:id,target:'timer',value:getTotal(+e.target.value,m,s)});
            }}>
                {([...Array(24).keys()]).map((o,i)=><option key={i}>{o}</option>)}
            </select>
        </div>
        <div className="form-select" style={{margin:'0 0.2em'}}>
            <select value={m} onChange={(e)=>{
                setS(+e.target.value);
                setAgenda({id:id,target:'timer',value:getTotal(h,+e.target.value,s)});
            }}>
                {([...Array(60).keys()]).map((o,i)=><option key={i}>{o}</option>)}
            </select>
        </div>
        <div className="form-select" style={{margin:'0 0.2em'}}>
            <select value={s} onChange={(e)=>{
                setS(+e.target.value);
                setAgenda({id:id,target:'timer',value:getTotal(h,m,+e.target.value)});
            }}>
                {([...Array(60).keys()]).map((o,i)=><option key={i}>{o}</option>)}
            </select>
        </div>
    </div>;
}

const TopicInput = ({id,o,setAgenda}) => {
    return <input className="form-input" type="text" value={o.topic} onChange={(e)=>{
            setAgenda({id:id,target:'topic',value:e.target.value});
        }} placeholder="Enter a Topic" />;
}

const OptionsList = ({agenda,setAgenda}) => {
    return <form className="agenda-form">
        <div className="display-flex agenda-item">
            <div className="flex-none" style={{padding:'0 0.85em'}}>&nbsp;</div>
            <div className="flex-stretch">
                Title
            </div>
            <div className="flex-none" style={{padding:'0 0.85em'}}>HH</div>
            <div className="flex-none" style={{padding:'0 0.85em'}}>MM</div>
            <div className="flex-none" style={{padding:'0 0.85em'}}>SS</div>
        </div>
        {agenda.map((o,i)=><div key={i} className="display-flex agenda-item">
            <div className="flex-none">
                <button type="button" className="form-button" onClick={()=>{setAgenda({id:i,target:false})}}>-</button>
            </div>
            <div className="flex-stretch">
                <TopicInput id={i} o={o} setAgenda={setAgenda} />
            </div>
            <div className="flex-none">
                <TimerInputSet id={i} o={o} setAgenda={setAgenda} />
            </div>
        </div>)}
    </form>
}

export const OptionsPage = ({agenda,setAgenda}) => {
    return <div>
        <OptionsList agenda={agenda} setAgenda={setAgenda} />
        <div style={{marginTop:'1em'}}>
            <button className="form-button" type="button" onClick={()=>{setAgenda(false)}}>+</button>
        </div>
        <hr/>
        <r.Link to="/run">Run Agenda</r.Link>
    </div>;
}