import React, { useReducer, useEffect, useRef } from 'react';

import * as r from 'react-router-dom';

function useInterval(callback, delay) {
    const savedCallback = useRef();
  
    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);
  
    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

const lz = (n,e) => (+n+Math.pow(10,e)+'').substr(1);

const toTime = (t) => {
    let s, m, h;
    if(t<=0) {
        s = 0;
        m = 0;
        h = 0;
    } else {
        h = t/3600000;
        let hr = t % 3600000;
        m = hr / 60000;
        let mr = hr % 60000;
        s = mr / 1000;
    }
    const showUnit = (u) => (n) => {
        let f = Math.floor(n);
        return f?`${f}${u}`:'';
    }
    const shows = showUnit('s');
    const showm = showUnit('m');
    const showh = showUnit('h');
    let result = [
        h,m,s, // individual values
        `${showh(h)} ${showm(m)} ${shows(s)}`,
        `${Math.floor(h)}:${lz(Math.floor(m)%60,2)}:${lz(Math.round(s)%60,2)}`
    ];
    return result;
}

const countDown = (t,agenda) => {
    let add = agenda.reduce((r,o)=>r+o.timer,0);
    return add-t;
}

const initialState=(o)=>({
    timer:null,
    time:false,
    index:0,
    stoppage:0,
    stoppageStart:false,
    cumulative:0,
    start:0,
    done:false,

    range:0,
    total:o.reduce((r,o)=>r+o.timer,0)
});

export const RunPage = ({agenda}) => {
    let [d,dispatch] = useReducer((s,a)=>({...s,...a}),initialState(agenda));

    let timerDefault = 30;




    const handlePlayButton = () => {
        if(d.stoppageStart) {
            dispatch({stoppage:(new Date()-d.stoppageStart+d.stoppage),stoppageStart:false});
        }
        if(d.done) dispatch({...initialState(agenda),timer:timerDefault})
        else dispatch({timer:timerDefault});
    }
    const handlePauseButton = () => {
        dispatch({stoppageStart:new Date(), timer:null});
    }
    const handleStopButton = () => {
        dispatch({...initialState(agenda),timer:null});
    }



    const run = () => {
        let s = new Date();
        if(d.start===0) dispatch({start:s}); 
        else s = d.start;

        let t = new Date();
        let diff = t - s - d.stoppage;
        let nextindex = d.index+1;

        let targetdistance = Math.floor(agenda[d.index].timer/timerDefault);
        let diffdistance = Math.floor((diff-d.cumulative)/timerDefault);

        // console.log(diff,d.cumulative,d.index,diffdistance,targetdistance,diffdistance >= targetdistance)
        if(diffdistance >= targetdistance) {
            d.cumulative += agenda[d.index].timer;
            if(agenda[nextindex]===undefined) {
                dispatch({timer:null,done:true});
            } else dispatch({index:nextindex});
        }
        dispatch({time:diff,range:diff/d.total});
    }
    useInterval(run,d.timer);


    return <div>
        <div>
            <r.Link to="/">Back to Options</r.Link>
        </div>
        <hr />
        <div className="countdowns">
            <div style={{margin:'1em 0'}}>
                <div className="display-flex">
                    <strong className="flex-stretch">Timer</strong>
                    <div className="flex-none timer">
                        {toTime(d.time)[4]}
                    </div>
                </div>
                <div className="display-flex">
                    <strong className="flex-stretch">Countdown</strong>
                    <div className="flex-none timer">
                        {toTime(countDown(d.time,agenda))[4]}
                    </div>
                </div>
                {d.stoppage>0 && <>
                <div className="display-flex">
                    <strong className="flex-stretch">Stoppage</strong>
                    <div className="flex-none timer">
                        {toTime(d.stoppage)[4]}
                    </div>
                </div>
                <div className="display-flex">
                    <strong className="flex-stretch">Total</strong>
                    <div className="flex-none timer">
                        {toTime(d.stoppage+d.time)[4]}
                    </div>
                </div>
                </>}
            </div>
        </div>
        <div className="play-buttons display-flex">
            <div className="flex-stretch">
                <button className="form-button" type="button" onClick={handlePlayButton} disabled={(d.timer&&!d.stoppageStart)}>Play</button>
            </div>
            <div className="flex-stretch" style={{margin:'0 1em'}}>
                <button className="form-button" type="button" onClick={handlePauseButton} disabled={!d.timer}>Pause</button>
            </div>
            <div className="flex-stretch">
                <button className="form-button" type="button" onClick={handleStopButton} disabled={(!d.timer&&!d.stoppageStart)}>Stop</button>
            </div>
        </div>
        <hr/>
        <div>
            <input style={{width:'100%'}} type="range" step="0.0001" min="0" max="1" value={d.range} readOnly />
        </div>
        <div className="option-list">
            {agenda.map((o,i)=><div key={i} className={`option-item ${d.index===i?'active':''}`}>
                {o.topic} {o.timer>0?' - '+toTime(o.timer)[3]:''}
            </div>)}
        </div>
    </div>;
}